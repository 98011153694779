.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 6.5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
}

.experience__details {
  display: flex;
  gap: 3rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.skills__list {
  max-width: 550px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

/* ======================= Media Queries (Medium Devices) ======================= */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
    column-gap: 5rem;
  }
}

/* ======================= Media Queries (Small Devices) ======================= */

@media screen and (max-width: 600px) {
  .experience__container {
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}
